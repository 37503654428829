<template>
  <a-drawer width="80%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="关注fackbook奖励金币数" prop="facebookNum" >
        <a-input-number :min="0" :precision="0"  v-model="form.facebookNum" placeholder="请输入关注fackbook奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="关注youtube奖励金币数" prop="youtubeNum" >
        <a-input-number :min="0" :precision="0"   v-model="form.youtubeNum" placeholder="请输入关注youtube奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="关注tiktok奖励金币数" prop="tiktok" >
        <a-input-number :min="0" :precision="0"   v-model="form.tiktok" placeholder="请输入关注tiktok奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="打开消息通知奖励金币数" prop="openMessage" >
        <a-input-number :min="0" :precision="0"   v-model="form.openMessage" placeholder="请输入打开消息通知奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="设置爱好奖励金币数" prop="likeNum" >
        <a-input-number :min="0" :precision="0"   v-model="form.likeNum" placeholder="请输入设置爱好奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="观看一集广告奖励金币数" prop="advertisementNum" >
        <a-input-number :min="0" :precision="0"   v-model="form.advertisementNum" placeholder="请输入观看一集广告奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="观看市场5分钟奖励金币数" prop="lookVideoOne" >
        <a-input-number :min="0" :precision="0"   v-model="form.lookVideoOne" placeholder="请输入观看市场5分钟奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="观看市场10分钟奖励金币数" prop="lookVideoTwo" >
        <a-input-number :min="0" :precision="0"   v-model="form.lookVideoTwo" placeholder="请输入观看市场10分钟奖励金币数" />
      </a-form-model-item>
      <a-form-model-item label="观看市场15分钟奖励金币数" prop="lookVideoThree" >
        <a-input-number :min="0" :precision="0"   v-model="form.lookVideoThree" placeholder="请输入观看市场15分钟奖励金币数" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTaskConfig, addTaskConfig, updateTaskConfig } from '@/api/taskConfig/taskConfig'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        facebookNum: null,

        youtubeNum: null,

        tiktok: null,

        openMessage: null,

        likeNum: null,

        advertisementNum: null,

        lookVideoOne: null,

        lookVideoTwo: null,

        lookVideoThree: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        facebookNum: null,
        youtubeNum: null,
        tiktok: null,
        openMessage: null,
        likeNum: null,
        advertisementNum: null,
        lookVideoOne: null,
        lookVideoTwo: null,
        lookVideoThree: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTaskConfig({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTaskConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTaskConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
