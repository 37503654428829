import request from '@/utils/request'


// 查询任务奖励配置列表
export function listTaskConfig(query) {
  return request({
    url: '/user/task-configs/list',
    method: 'get',
    params: query
  })
}

// 查询任务奖励配置分页
export function pageTaskConfig(query) {
  return request({
    url: '/user/task-configs/page',
    method: 'get',
    params: query
  })
}

// 查询任务奖励配置详细
export function getTaskConfig(data) {
  return request({
    url: '/user/task-configs/detail',
    method: 'get',
    params: data
  })
}

// 新增任务奖励配置
export function addTaskConfig(data) {
  return request({
    url: '/user/task-configs/add',
    method: 'post',
    data: data
  })
}

// 修改任务奖励配置
export function updateTaskConfig(data) {
  return request({
    url: '/user/task-configs/edit',
    method: 'post',
    data: data
  })
}

// 删除任务奖励配置
export function delTaskConfig(data) {
  return request({
    url: '/user/task-configs/delete',
    method: 'post',
    data: data
  })
}
